<template>
  <main class="app__page doc">
    <div class="doc__container">
      <h1 class="doc__title title">
        {{ $t("pages.documentation.title") }}
      </h1>
      <div class="doc__list">
        <a
          v-for="(doc, docIndex) in documents"
          :key="docIndex"
          :href="doc.link"
          class="doc__item"
          target="_blank"
        >
          {{ doc.name }}
          <span class="doc__download">
            {{ $t("download") + checkExt(doc.link) }}
          </span>
        </a>
      </div>
    </div>
    <PageFooter />
  </main>
</template>

<script>
import { pageLoaded } from "@/mixins/pageLoaded.js";
import PageFooter from "@/components/PageFooter";
export default {
  name: "documentation",
  components: { PageFooter },
  mixins: [pageLoaded],
  data() {
    return {
      documents: [],
      isMounted: false,
      hasResponse: false,
      loadQty: 2 // смонтировано + документы
    };
  },
  computed: {
    api() {
      return this.$store.state.api;
    },
    locale() {
      return this.$i18n.locale;
    }
  },
  methods: {
    getDocuments() {
      this.$axios({
        method: "get",
        url: this.api + "/documents",
        timeout: 90000
      })
        .then((response) => {
          if (response && response.data) {
            this.documents = response.data;
          } else {
            this.$toast.error(this.$t("error"));
          }
          this.$nextTick(() => {
            this.loaded = this.loaded + 1;
          });
        })
        .catch((error) => {
          this.$nextTick(() => {
            this.loaded = this.loaded + 1;
          });
          this.$toast.error(this.$t("error"));
          if (error && error.response) console.log(error.response);
        });
    },
    checkExt(url) {
      const index = url.lastIndexOf(".");
      return index < 0 ? "" : " " + url.substring(index + 1);
    }
  },
  created() {
    this.getDocuments();
  },
  watch: {
    locale() {
      this.getDocuments();
    }
  }
};
</script>

<style scoped lang="less">
.doc {
  .padding-page-top;
  &__container {
    margin-left: auto;
    margin-right: auto;
    padding-left: 40px;
    padding-right: 40px;
    max-width: 1320px;
    width: 100%;
    @media @bw1510 {
      box-sizing: border-box;
      padding-left: 100px;
      padding-right: 100px;
      max-width: 100%;
    }
    @media @bw1170 {
      padding-left: 30px;
      padding-right: 30px;
    }
    @media @bw768 {
      padding-left: 20px;
      padding-right: 20px;
    }
  }
  &__title {
    display: block;
    padding-bottom: 20px;
    border-bottom: 1px solid @white_10;
    margin-bottom: 40px;
    @media @bw768 {
      margin-bottom: 20px;
    }
  }
  &__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid @white_10;
    padding: 20px 0;
    width: 100%;
    text-decoration: none;
    font-weight: 700;
    font-family: @font1;
    font-size: 24px;
    line-height: 1.3;
    color: @white;
    transition: color 0.2s, border-bottom 0.2s;
    @media @bw768 {
      margin-bottom: 20px;
      flex-direction: column;
      align-items: flex-start;
      font-size: 18px;
      font-weight: 500;
    }
    &:hover {
      border-bottom: 1px solid @gold;
      color: @gold;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
  &__download {
    margin-left: 60px;
    flex-shrink: 0;
    text-decoration: none;
    border: none;
    outline: none;
    padding: 24px 72px;
    border-radius: 40px;
    background-color: @gold;
    font-weight: 700;
    font-family: @font2;
    font-size: 18px;
    line-height: 1;
    color: @dark2;
    transition: background-color 0.2s;
    text-transform: uppercase;
    @media @bw1170 {
      margin-left: 50px;
    }
    @media @bw768 {
      margin-top: 20px;
      margin-left: 0px;
      padding: 0;
      background-color: transparent;
      color: @gold;
    }
    &:hover {
      background-color: #cbaa62;
    }
  }
}
</style>
